import LatLon from 'geodesy/latlon-spherical';
import { transaction } from 'mobx';
import { IntlShape } from 'react-intl';
import {
  getBerthAngleTN,
  getBerthToShipAngleTN,
  getMaxFenderThickness,
  getVesselCentreOnBerth,
  getVesselCentreOnVessel
} from '../../helpers/mapHelpers';
import { Berth, Fender } from '../../interfaces/PortData';
import { AutomoorResponse, Bollard as BollardScenario, Fender as FenderScenario, Line, Point2D, Vessel } from '../../interfaces/VesselScenario';
import { PortStore, VesselScenarioStore } from '../../stores';
import { RootStore } from '../../stores/RootStore';
import { AllBollard, SnackbarItem } from '../../stores/types';
import { calculateAutoMooring } from '../apiHelper';
import { maRound } from '../common';

export interface FenderConnectivity {
  name: string;
  profileId: number;
  coordinates: {
    x: number;
    y: number;
    z: number;
  };
  direction: number;
  fenderOffset: number;
  pointOriginal: number[];
  point: number[];
  zAboveWharfDeck: number;
  zAboveWharfUnit: string;
}

/**
 * Given informaiton on vesel and mooring this will return a list of the fenders in use for the specific mooring
 * @param {PortStore} portStore The portStore
 * @param {VesselScenarioStore} vesselScenarioStore The vesselScenarioStore
 * @param {Berth} berth
 * @param {object} fenders
 * @param {value} bufferInMeters- The buffer to search beyond the LOA of the vessel
 */
const getUsedFenders = (
  portStore: PortStore,
  vesselScenarioStore: VesselScenarioStore,
  berth: Berth,
  fenders: Fender[],
  bufferInMeters: number,
) => {
  const { mooring, vessel } = vesselScenarioStore.scenarioData.data;
  const vesselCentreOnBerth = getVesselCentreOnBerth(berth, mooring, vessel);

  const berthName = vesselScenarioStore.scenarioData.data.mooring.berthName;
  const allBerths: Berth[] = portStore.getAllBerths(); // All berths with data in a flat list.
  const adjacentBerths = portStore.getAdjacentBerths(berthName, allBerths);

  let fendersAtBerth: Fender[] = [];
  // Get fenders at all adjacent berths:
  for (let adjacentBerthName of adjacentBerths) {
    let adjacentBerth = allBerths.find((berth) => berth.berthName === adjacentBerthName);
    for (let fenderUse of adjacentBerth.fenders) {
      const fenderOut = fenders.find(
        (b) => b.point[0] === fenderUse.point[0] && b.point[1] === fenderUse.point[1] && b.name === fenderUse.name,
      );
      fendersAtBerth.push(fenderOut);
    }
  }

  const filteredFenders = fendersAtBerth.filter((fender) => {
    const fenderPoint = new LatLon(fender.point[1], fender.point[0]);
    const distance = vesselCentreOnBerth.distanceTo(fenderPoint);
    return distance < vessel.loa / 2 + bufferInMeters && (!fender.status || fender.status === 'enabled');
  });

  const fendersUsed: FenderScenario[] = filteredFenders.map((filteredFender, index) => {
    const direction = getBerthToShipAngleTN(berth);

    return {
      id: index,
      name: filteredFender.name,
      profileId: filteredFender.profileId,
      coordinates: {
        x: filteredFender.point[0],
        y: filteredFender.point[1],
        z: filteredFender.point[2],
      },
      direction,
      fenderOffset: filteredFender.fenderOffset,
      pointOriginal: filteredFender.pointOriginal,
      point: filteredFender.point,
      zAboveWharfDeck: filteredFender.zAboveWharfDeck,
      zAboveWharfUnit: filteredFender.zAboveWharfUnit,
    };
  });

  return fendersUsed;
};

/**
 * Given informaiton on vesel and mooring this will return a list of the bollards that can be used for the specific mooring
 * @param {PortStore} portStore The portStore
 * @param {VesselScenarioStore} vesselScenarioStore The vesselScenarioStore
 * @param {LatLon} vesselCentre Center coordinate of vessel
 * @param {Vessel} vessel vessel data used.
 */
const getUsedBollards = (
  portStore: PortStore,
  vesselScenarioStore: VesselScenarioStore,
  vesselCentre: LatLon,
  vessel: Vessel,
): BollardScenario[] => {
  const bollardsAtBerth = getBollardsAtBerth(portStore, vesselScenarioStore);

  let nBollards = 0;
  // Set used bollards and filter by distance.
  const bollardsUsed: BollardScenario[] = bollardsAtBerth
    .filter((bollard) => {
      const bollardPoint = new LatLon(bollard.point[1], bollard.point[0]);
      const distance: number = vesselCentre.distanceTo(bollardPoint);
      return distance < vessel.loa * 2;
    })
    .map((filteredBollard) => {
      return {
        ...filteredBollard,
        name: filteredBollard.name,
        profileId: filteredBollard.profileId,
        coordinates: {
          x: filteredBollard.point[0],
          y: filteredBollard.point[1],
          z: filteredBollard.point[2],
        },
        associatedProfile: undefined,
        point: undefined,
        index: nBollards++,
      };
    });

  return bollardsUsed;
};

/**
 * Return a list of the bollards available at the selected berth
 * @param {PortStore} portStore The portStore
 * @param {VesselScenarioStore} vesselScenarioStore The vesselScenarioStore
 * @param {Boolean} includePlanned Include planned bollards
 * @param {Boolean} includeAdjacentBerths Include bollards from adjacent berths
 */
const getBollardsAtBerth = (
  portStore: PortStore,
  vesselScenarioStore: VesselScenarioStore,
  includePlanned = false,
  includeAdjacentBerths = true,
): BollardScenario[] => {
  if (!vesselScenarioStore?.scenarioData?.data?.mooring?.berthName) return [];

  const bollards: BollardScenario[] = portStore.allBollards.map((bollard, originalIndex) => {
    return {
      ...bollard,
      originalIndex,
      id: originalIndex,
      index: 0,
      coordinates: {
        x: bollard.point[0],
        y: bollard.point[1],
        z: bollard.point[2],
      },
      point: bollard.point,
      profileId: bollard!.profileId,
      slots: portStore.getAvailableLines(bollard as AllBollard, vesselScenarioStore.scenarioData, true, null),
      numberOfBowLines:
        bollard.associatedProfile?.numberOfBowLines === null || bollard.associatedProfile.numberOfBowLines === undefined
          ? -1
          : bollard.associatedProfile.numberOfBowLines,
      numberOfBreastLines:
        bollard.associatedProfile?.numberOfBreastLines === null ||
        bollard.associatedProfile.numberOfBreastLines === undefined
          ? -1
          : bollard.associatedProfile.numberOfBreastLines,
      numberOfSpringLines:
        bollard.associatedProfile?.numberOfSpringLines === null ||
        bollard.associatedProfile.numberOfSpringLines === undefined
          ? -1
          : bollard.associatedProfile.numberOfSpringLines,
    };
  });

  const terminalName = vesselScenarioStore.scenarioData.data.mooring.terminalName;
  const berthName = vesselScenarioStore.scenarioData.data.mooring.berthName;
  const allBerths = portStore.getAllBerths(); // All berths with data in a flat list.
  const adjacentBerths = includeAdjacentBerths ? portStore.getAdjacentBerths(berthName, allBerths) : [berthName];

  let bollardsAtBerth: BollardScenario[] = [];

  // Get Enabled bollards at all adjacent berths:
  for (let adjacentBerthName of adjacentBerths) {
    let adjacentBerth: Berth = allBerths.find((berth) => berth.berthName === adjacentBerthName);
    for (let bollardUse of adjacentBerth.bollards) {
      const bollardOut = bollards.find((b) => b.point[0] === bollardUse.point[0] && b.point[1] === bollardUse.point[1]);
      if (bollardUse.status === 'enabled' || (includePlanned && bollardUse.status === 'planned')) {
        bollardsAtBerth.push(bollardOut);
      }
    }
  }

  // Get shared enabled bollards in the terminal:
  let terminal = portStore.getTerminal(terminalName);
  if (terminal?.sharedBollards != null && includeAdjacentBerths) {
    for (let bollardUse of terminal?.sharedBollards) {
      const bollardOut = bollards.find((b) => b.point[0] === bollardUse.point[0] && b.point[1] === bollardUse.point[1]);
      if (bollardUse.status === 'enabled' || (includePlanned && bollardUse.status === 'planned')) {
        bollardsAtBerth.push(bollardOut);
      }
    }
  }

  return bollardsAtBerth;
};

// const matlabLineNames = {
//   1: 'Bow/Stern',
//   2: 'F/A Breast',
//   3: 'F/A Spring',
//   4: 'F/A Spring',
//   5: 'F/A Breast',
//   6: 'Bow/Stern',
// };

/**
 * Utilising ship data, calculate the 'best-guess' mooring connections automatically based on a number of factors.
 * (Another way of using calculateConnectivity using the scenarioDTO and the portData)
 * @param {scenarioDTO} scenarioData from the context, based on scenario DTO
 * @param {portData} portData from the context, has all the berths, fenders, bollards and their profiles
 */
const calculateConnectivity = (
  rootStore: RootStore,
  runAutomoor: boolean,
  lockMooringArrangement: boolean,
  intl: IntlShape,
  completeCallback: () => void,
) => {
  const { appStateStore, portStore, vesselScenarioStore, configStore } = rootStore;
  const { mooring, vessel, port, environmentalConditions } = vesselScenarioStore.scenarioData.data;
  const { validity } = vesselScenarioStore.scenarioData;
  let nearestVesselDistances = []; // TODO: Typing on this - This doesnt have any typing at any level.

  console.info('%c running calculateConnectivity', 'background-color:#0000ff;color:white;');

  if (!mooring.berthName) {
    if (completeCallback) completeCallback();

    return;
  }

  const logs: string[] = [`'${'='.repeat(50)}\n| Auto Mooring\n${'='.repeat(50)}`];
  const berth: Berth = portStore.getBerth(vesselScenarioStore.scenarioData.data.mooring.berthName);

  // Find relevant fender data
  const fenders = portStore.getAllFenders();

  if (runAutomoor) {
    appStateStore.autoMoorCalculating = true;
  }

  // Update UI in ont hit
  transaction(() => {
    // Set the used fenders - All fenders within vessel +/- 40m.
    const fendersReduced: FenderScenario[] = getUsedFenders(portStore, vesselScenarioStore, berth, fenders, 40);
    port.fenders = fendersReduced;

    // Include the fenderprofiles of the fenders being used
    const fenderProfileIds = port.fenders.map((fender) => fender.profileId);

    port.fenderProfiles = portStore.portData.fenderProfiles.filter((fenderProfile) =>
      fenderProfileIds.includes(fenderProfile.fenderProfileId),
    );
    // Get max fender thickness of all fenders within the vessel from -loa/2 to loa/2 within vessel center:
    const fendersReducedWithinVessel: FenderScenario[] = getUsedFenders(
      portStore,
      vesselScenarioStore,
      berth,
      fenders,
      -vessel.loa * 0.05,
    ); // Reduce by part of loa to only take fenders on straight part of hull (handling of serious edge case here)
    const maxFenderThickness = getMaxFenderThickness(
      fendersReducedWithinVessel,
      port.fenderProfiles,
      berth.berthIsToTheRight,
    );

    mooring.maxFenderThickness = maxFenderThickness;
    // Move elsewhere
    const berthAngleTN = getBerthAngleTN(berth);

    mooring.mooredHeading =
      ((berth.berthIsToTheRight ? 0 : 180) + (mooring.portDock ? berthAngleTN + 180 : berthAngleTN)) % 360;

    const vesselCenterPoint = getVesselCentreOnVessel(
      berth,
      mooring,
      vessel,
      maxFenderThickness,
      configStore.appConfig.diagnostics.autoMooring,
    );
    mooring.latitude = vesselCenterPoint.lat;
    mooring.longitude = vesselCenterPoint.lon;
    // ---------------------

    // Get all bollards that should be used for mooring:
    const vesselCentre: LatLon = new LatLon(mooring.latitude, mooring.longitude);
    const bollards: BollardScenario[] = getUsedBollards(portStore, vesselScenarioStore, vesselCentre, vessel);
    port.bollards = bollards;

    if (!runAutomoor) {
      return; // Only do the above, but not re-calculate automoor if we are in manual mode
    }

    const proximitySummary = validity?.mooring.proximity.summary;

    if (proximitySummary) {
      nearestVesselDistances = proximitySummary.map((vessel) => ({
        scenarioId: vessel.scenarioId,
        distance: vessel.distance,
      }));
    }

    const portName: string = appStateStore.session.user.metadata.clientId;
    port.name = portName;

    const autoMooringType = configStore.appConfig.applicationSettings.automaticMooringType;

    // Remote auto mooring
    const autoMoorRequest = {
      mooringSetup: {
        port,
        vessel,
        mooring,
        environmentalConditions,
      },
      nearestVesselDistances,
      autoMooringType,
    };

    // Leave for debug analysis
    if (configStore.appConfig.diagnostics.autoMooring) {
      console.log('autoMoorRequest pre', JSON.stringify(autoMoorRequest));
    }

    if (!lockMooringArrangement) {
      const saveAutoMooring = (result: any, completeCallback: () => void) => {
        // Update UI in ont hit
        transaction(() => {
          const inputMooringArrangement =
            vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[
              vesselScenarioStore.scenarioData.data.mooring.mooringArrangementName
            ];
          const mooringAlreadyExists =
            !!vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[result.mooringArrangementName];

          vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[result.mooringArrangementName] = (
            mooringAlreadyExists
              ? vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[result.mooringArrangementName]
              : result.mooringArrangement
          )
            .map((line: Line) => {
              // find the line.id in what you get from alex
              const originalLine = inputMooringArrangement.find((l) => l.id === line.id);
              const remoteLine: Line = result.mooringArrangement.find((l: Line) => l.id === line.id);

              // TODO: return type here:
              if (remoteLine) {
                return {
                  ...originalLine,
                  lineType: remoteLine.lineType,
                  fairleadId: remoteLine.fairleadId,
                  bollardIndex: port.bollards[remoteLine.bollardIndex].originalIndex, // Take as original index from entire bollard list
                  fairleadWinchLength: maRound(remoteLine.fairleadWinchLength),
                  bollardFairleadLength: maRound(remoteLine.bollardFairleadLength),
                  lineLengthMax: maRound(remoteLine.lineLengthMax),
                  lineLengthMin: maRound(remoteLine.lineLengthMin),
                  lineAngleVerticalMax: maRound(remoteLine.lineAngleVerticalMax),
                  lineAngleHorizontalMax: maRound(remoteLine.lineAngleHorizontalMax),
                  lineAngleHorizontalMin: maRound(remoteLine.lineAngleHorizontalMin),
                  lineAngleHorizontalIdeal: maRound(remoteLine.lineAngleHorizontalIdeal),
                  // Reset constant tension winch on calculate connectivity:
                  shoreTension: undefined,
                  constantTensionWinchSelected: false,
                  // Reset selected state on calculate connectivity:
                  selected: false,
                };
              } else {
                if (configStore.appConfig.diagnostics.autoMooring) {
                  console.log(`Line for id ${line.id} connection failed. No suitable bollards found.`);
                }

                return null;
              }
            })
            .filter((f) => {
              return f !== null;
            });

          vesselScenarioStore.scenarioData.data.mooring.mooringArrangementName = result.mooringArrangementName;

          appStateStore.autoMoorCalculating = false;
        });

        if (completeCallback) {
          completeCallback();
        }
      };

      if (configStore.appConfig.diagnostics.autoMooring) {
        console.log(logs.join('\n'));
        console.log('Waiting on response from server...');
        console.time('AutomoorProfilingFull');
      }

      const wipeConnections = () => {
        // If we cannot moor at this location, ensure we have wiped out fairleadId + bollardIndex
        vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[
          vesselScenarioStore.scenarioData.data.mooring.mooringArrangementName
        ] = vesselScenarioStore.scenarioData.data.mooring.mooringArrangements[
          vesselScenarioStore.scenarioData.data.mooring.mooringArrangementName
        ].map((ma) => ({
          ...ma,
          fairleadId: null,
          bollardIndex: null,
        }));
      };

      // TODO: Step through below and figure out where intermittent warnings show up from when automoor is run.
      calculateAutoMooring(
        appStateStore,
        vesselScenarioStore.scenarioData.id ?? 'new',
        autoMoorRequest,
        configStore.appConfig.diagnostics,
        (result: AutomoorResponse ) => {
          if (configStore.appConfig.diagnostics.autoMooring) {
            console.timeEnd('AutomoorProfilingFull');
            console.log('autoMoor response', JSON.stringify(result));
          }

          // Only continue post processing of automoor such as setting the arrangement and redrawing
          // if we have no further automoor requests in the queue.
          // If they are present in the queue from multiple inputs firing, the MA lines can jump around
          // and orange warnings can be intermittently present.
          if (appStateStore.pendingAutomoorRequests === 0) {
            if (result.simulationNotes.length > 0) {
              let itemList: SnackbarItem[] = [];
              result.simulationNotes.forEach((note) => {
                if (note.data === 'bollardOverloadWarning') {
                  itemList.push({
                    id: note.data,
                    title: null,
                    description: intl.formatMessage({
                      id: `engine.automoor.errorMessages.bollardOverloadWarning`,
                      defaultMessage: note.data,
                    }, {
                        0: autoMoorRequest.mooringSetup.mooring.mooringArrangementName,
                        1: result.mooringArrangementName,
                      }
                    ).toString(),
                  });
                } else {
                itemList.push({
                  id: note.data,
                  title: null,
                  description: intl.formatMessage({
                    id: `engine.automoor.errorMessages.${note.data}`,
                    defaultMessage: note.data,
                  }),
                });
              }
              });
              if (result.mooringArrangement.length > 0 && itemList.length > 0 && appStateStore.showWarnings) {
                rootStore.appStateStore.showSnackbar(
                  'engine.automoor.errorMessages.succeedWithWarnings',
                  itemList,
                  'warning',
                  7500,
                );
              } else {
                appStateStore.showWarnings &&
                  rootStore.appStateStore.showSnackbar(
                    'engine.automoor.errorMessages.generalError',
                    itemList,
                    'warning',
                    itemList.length > 0 ? 7500 : 5000,
                  );
              }
            }
          }
          if (appStateStore.pendingAutomoorRequests === 0 && result.mooringArrangement.length > 0) {
            appStateStore.autoMoorFailed = false;
            appStateStore.autoMoorSuccesful = true;

            saveAutoMooring(result, completeCallback);
          } else if (appStateStore.pendingAutomoorRequests === 0) {
            appStateStore.autoMoorCalculating = false;
            appStateStore.autoMoorFailed = true;
            appStateStore.autoMoorSuccesful = false;

            wipeConnections();
          }
        },
        () => {
          wipeConnections();
          appStateStore.showWarnings &&
            rootStore.appStateStore.showSnackbar('engine.automoor.errorMessages.generalError', null, 'toast');
        },
      );
    } else {
      appStateStore.autoMoorCalculating = false;
      completeCallback();
    }
  });
};

// Set the full deckplane - Mirror the deckplane if required.
const getFullDeckPlane = (deckPlane: Point2D[], beam: number) => {
  const deckPlaneBeam =
    Math.max(...deckPlane.map((point) => point.y)) - Math.min(...deckPlane.map((point) => point.y));
  
  if (beam - deckPlaneBeam > 1) {
    // Mirror deckplane:
    deckPlane = [
      ...deckPlane,
      ...deckPlane
        .slice()
        .reverse()
        .map((point: Point2D) => {
          return {
            x: point.x,
            y: -1 * point.y,
          };
        }),
    ];
  };

  return deckPlane;
}

export { calculateConnectivity, getBollardsAtBerth, getFullDeckPlane };
